export default function InputLabel({
    value,
    className = '',
    children,
    required = false,
    ...props
}) {
    return (
        <label
            {...props}
            className={
                `form-label ` +
                className
            }
        >
            {value ? value : children}

            {required && <span className="text-danger">*</span>}
        </label>
    );
}
